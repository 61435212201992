import { hambergerToggle } from "./assets/js/modules/hamburgertoggle.js";
// import { initPcNav } from "./assets/js/modules/pcNav.js";

import { fadeIn } from "./assets/js/modules/fadeInAni.js";
import { spNavDropToggle } from "./assets/js/modules/spDropToggle.js";
import { pageTopBtnAction } from "./assets/js/modules/pagetopbtn-action.js";
import { cookie } from "./assets/js/modules/cookie.js";
import { loadingAni } from "./assets/js/modules/loadding-ani.js";
import { Animation01 } from "./assets/js/modules/animation01.js";
import { customScrollbar } from "./assets/js/modules/custom-scroll.js";
import { modal } from "./assets/js/modules/modal.js";
const $body = $("body");
spNavDropToggle();

// initPcNav();
hambergerToggle();
pageTopBtnAction();
cookie();
loadingAni();
fadeIn();
Animation01();
modal();

if ($body.hasClass("contact")) {
  customScrollbar();
}
